var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("el-header", { staticClass: "app-header flex clearfix" }, [
    _c("div", { staticClass: "fl" }, [
      _c("div", { staticClass: "logo-container" }, [
        _c("img", {
          attrs: { src: require("../../assets/images/app_logo.png") },
        }),
      ]),
    ]),
    _c("div", { staticClass: "fr app-personal" }, [
      _c("span", { staticClass: "pointer" }, [
        _vm._v(" 您好！" + _vm._s(_vm.userName) + " "),
      ]),
      _c("span", { staticClass: "pointer app-text", on: { click: _vm.exit } }, [
        _vm._v("退出"),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }