export default {
  methods: {
    // 动态赋值 label 值
    optionName (idx) {
      switch (idx) {
        case 0:
          return '选项A：'
        case 1:
          return '选项B：'
        case 2:
          return '选项C：'
        case 3:
          return '选项D：'
        case 4:
          return '选项E：'
        case 5:
          return '选项F：'
        case 6:
          return '选项G：'
        case 7:
          return '选项H：'
        case 8:
          return '选项I：'
        case 9:
          return '选项J：'
      }
    },

    // 动态赋值 label 值
    optionLetterName (idx) {
      switch (idx) {
        case 0:
          return 'A'
        case 1:
          return 'B'
        case 2:
          return 'C'
        case 3:
          return 'D'
        case 4:
          return 'E'
        case 5:
          return 'F'
        case 6:
          return 'G'
        case 7:
          return 'H'
        case 8:
          return 'I'
        case 9:
          return 'J'
      }
    }
  }
}
