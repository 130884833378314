<template>
  <el-container class="container">
    <el-header class="header">
      <header-top showTit="返回" :isLink="true"></header-top>
    </el-header>
    <el-main class="test-main">
      <div class="task-con">
        <el-row :gutter="24">
          <el-col :xs="24" :sm="24" :md="24" :lg="18" :xl="18">
            <div class="panel">
              <span v-text="testTask.title" class="task-tit"></span>
            </div>
            <div
              class="panel"
              v-for="(item, index) in testTask.questionList"
              :key="index"
              :id="'anchor_'+ (index+1)"
            >
              <el-row class="topicTit">
                <span class="top-num">{{index+1}}.</span>
                <span class="top-type">【 {{item.question.type}} 】</span>
                <span class="top-score">{{item.score}} 分</span>
              </el-row>
              <el-row>
                <el-row v-html="item.question.stem" class="task-title"></el-row>
              </el-row>

              <ul class="label-con">
                <li class="label-list clearfix" v-for="(label, index) in item.question.itemList" :key="index">
                  <span class="fl mr-10" v-text="optionLetterName(index) + '.'"></span>
                  <div class="fl" v-html="label.content"></div>
                </li>
              </ul>
              <template v-if="item.question.type === '单选'">
                <el-radio-group
                  v-model="item.answer"
                  v-for="(label, index) in item.question.itemList"
                  :key="index"
                  @change="optionChange"
                >
                  <el-radio :label="optionLetterName(index)"></el-radio>
                </el-radio-group>
              </template>

              <template v-if="item.question.type === '多选'">
                <el-checkbox-group
                  @change="optionChange"
                  v-model="item.answer"
                  v-for="(label, index) in item.question.itemList"
                  :key="index"
                >
                  <el-checkbox :label="optionLetterName(index)"></el-checkbox>
                </el-checkbox-group>
              </template>

              <template v-if="item.question.type === '简答'">
                <el-input
                  type="textarea"
                  :rows="5"
                  placeholder="请输入你的答案"
                  @input="inputChange($event)"
                  v-model="item.answer"
                ></el-input>
              </template>
            </div>
          </el-col>
          <!-- 答题卡 -->
          <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
            <el-row class="task-fixed">
              <div class="task-dtk">
                <h5 class="card-tit">答题卡</h5>
                <ul :class="[Boolean(isSeen)?'card-list-show':'card-list' + ' clearfix']" @mouseenter="onMouseOver" @mouseleave="onMouseOut">
                  <li
                    v-for="(item, index) of testTask.questionList"
                    :key="index"
                    @click="skipAnchor(index+1)"
                    :class="!item.answer || !item.answer.length ? '' : 'bg-card'"
                  >{{index+1}}</li>
                </ul>
                <el-row style="text-align: center;">
                  <el-button
                    size="small"
                    round
                    type="primary"
                    style="color: #212121;margin: 10px 0;"
                    :disabled="true"
                  >提交试卷</el-button>
                </el-row>
              </div>
            </el-row>
          </el-col>
        </el-row>
      </div>
    </el-main>
  </el-container>
</template>

<script>
import getOptionName from '@/mixins/getOptionName'
import HeaderTop from '@/components/HeaderTop'
import { getExamInfo } from '@/api/research/exam'

export default {
  mixins: [getOptionName],
  components: { HeaderTop },
  data () {
    return {
      answerList: [], // 答题卡-（数据）
      multipleOption: [], // 答题卡多选数据
      testTask: {},
      examID: '', // 试卷ID
      isSeen: false
    }
  },
  created () {
    this.examID = this.$route.query.examID
    this.getExamInfo()
  },
  methods: {
    // 题目锚点跳转
    skipAnchor (index) {
      let domAnchor = `#anchor_${index}`
      document.querySelector(domAnchor).scrollIntoView(true)
    },

    inputChange (e) {
      this.$forceUpdate()
    },

    optionChange () {
      this.$forceUpdate()
    },

    // 获取试卷详细信息
    async getExamInfo (id) {
      const res = await getExamInfo({
        id: this.examID
      })
      this.testTask = Object.assign({}, res.body)
      this.testTask.questionList.forEach((item, index) => {
        if (item.question.type === '单选') {
          item.answer = ''
        } else if (item.question.type === '多选') {
          item.answer = []
        }
      })
    },

    onMouseOver () {
      this.isSeen = true
    },

    onMouseOut () {
      this.isSeen = false
    }
  }
}
</script>

<style lang="scss" scoped>
.container{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background-color: #e5e5e5;
  height: 100%;
  .header {
    display: flex;
    align-items: center;
    box-sizing: border-box;
  }
  .test-main {
    background: #fff;
    width: 100%;
    height: calc(100vh - 60px);
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
  }
}
@import '../../style/paper.scss';
</style>
