var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-container",
    { staticClass: "container" },
    [
      _c(
        "el-header",
        { staticClass: "header" },
        [_c("header-top", { attrs: { showTit: "返回", isLink: true } })],
        1
      ),
      _c("el-main", { staticClass: "test-main" }, [
        _c(
          "div",
          { staticClass: "task-con" },
          [
            _c(
              "el-row",
              { attrs: { gutter: 24 } },
              [
                _c(
                  "el-col",
                  { attrs: { xs: 24, sm: 24, md: 24, lg: 18, xl: 18 } },
                  [
                    _c("div", { staticClass: "panel" }, [
                      _c("span", {
                        staticClass: "task-tit",
                        domProps: { textContent: _vm._s(_vm.testTask.title) },
                      }),
                    ]),
                    _vm._l(_vm.testTask.questionList, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "panel",
                          attrs: { id: "anchor_" + (index + 1) },
                        },
                        [
                          _c("el-row", { staticClass: "topicTit" }, [
                            _c("span", { staticClass: "top-num" }, [
                              _vm._v(_vm._s(index + 1) + "."),
                            ]),
                            _c("span", { staticClass: "top-type" }, [
                              _vm._v(
                                "【 " + _vm._s(item.question.type) + " 】"
                              ),
                            ]),
                            _c("span", { staticClass: "top-score" }, [
                              _vm._v(_vm._s(item.score) + " 分"),
                            ]),
                          ]),
                          _c(
                            "el-row",
                            [
                              _c("el-row", {
                                staticClass: "task-title",
                                domProps: {
                                  innerHTML: _vm._s(item.question.stem),
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "ul",
                            { staticClass: "label-con" },
                            _vm._l(
                              item.question.itemList,
                              function (label, index) {
                                return _c(
                                  "li",
                                  {
                                    key: index,
                                    staticClass: "label-list clearfix",
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "fl mr-10",
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.optionLetterName(index) + "."
                                        ),
                                      },
                                    }),
                                    _c("div", {
                                      staticClass: "fl",
                                      domProps: {
                                        innerHTML: _vm._s(label.content),
                                      },
                                    }),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                          item.question.type === "单选"
                            ? _vm._l(
                                item.question.itemList,
                                function (label, index) {
                                  return _c(
                                    "el-radio-group",
                                    {
                                      key: index,
                                      on: { change: _vm.optionChange },
                                      model: {
                                        value: item.answer,
                                        callback: function ($$v) {
                                          _vm.$set(item, "answer", $$v)
                                        },
                                        expression: "item.answer",
                                      },
                                    },
                                    [
                                      _c("el-radio", {
                                        attrs: {
                                          label: _vm.optionLetterName(index),
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                }
                              )
                            : _vm._e(),
                          item.question.type === "多选"
                            ? _vm._l(
                                item.question.itemList,
                                function (label, index) {
                                  return _c(
                                    "el-checkbox-group",
                                    {
                                      key: index,
                                      on: { change: _vm.optionChange },
                                      model: {
                                        value: item.answer,
                                        callback: function ($$v) {
                                          _vm.$set(item, "answer", $$v)
                                        },
                                        expression: "item.answer",
                                      },
                                    },
                                    [
                                      _c("el-checkbox", {
                                        attrs: {
                                          label: _vm.optionLetterName(index),
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                }
                              )
                            : _vm._e(),
                          item.question.type === "简答"
                            ? [
                                _c("el-input", {
                                  attrs: {
                                    type: "textarea",
                                    rows: 5,
                                    placeholder: "请输入你的答案",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.inputChange($event)
                                    },
                                  },
                                  model: {
                                    value: item.answer,
                                    callback: function ($$v) {
                                      _vm.$set(item, "answer", $$v)
                                    },
                                    expression: "item.answer",
                                  },
                                }),
                              ]
                            : _vm._e(),
                        ],
                        2
                      )
                    }),
                  ],
                  2
                ),
                _c(
                  "el-col",
                  { attrs: { xs: 24, sm: 24, md: 24, lg: 6, xl: 6 } },
                  [
                    _c("el-row", { staticClass: "task-fixed" }, [
                      _c(
                        "div",
                        { staticClass: "task-dtk" },
                        [
                          _c("h5", { staticClass: "card-tit" }, [
                            _vm._v("答题卡"),
                          ]),
                          _c(
                            "ul",
                            {
                              class: [
                                Boolean(_vm.isSeen)
                                  ? "card-list-show"
                                  : "card-list" + " clearfix",
                              ],
                              on: {
                                mouseenter: _vm.onMouseOver,
                                mouseleave: _vm.onMouseOut,
                              },
                            },
                            _vm._l(
                              _vm.testTask.questionList,
                              function (item, index) {
                                return _c(
                                  "li",
                                  {
                                    key: index,
                                    class:
                                      !item.answer || !item.answer.length
                                        ? ""
                                        : "bg-card",
                                    on: {
                                      click: function ($event) {
                                        return _vm.skipAnchor(index + 1)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(index + 1))]
                                )
                              }
                            ),
                            0
                          ),
                          _c(
                            "el-row",
                            { staticStyle: { "text-align": "center" } },
                            [
                              _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    color: "#212121",
                                    margin: "10px 0",
                                  },
                                  attrs: {
                                    size: "small",
                                    round: "",
                                    type: "primary",
                                    disabled: true,
                                  },
                                },
                                [_vm._v("提交试卷")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }